import React, { useEffect, useMemo, useState } from "react";
import "../index.css";
import axios from "axios";
// import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css"; // Core grid CSS, always needed
import "ag-grid-community/styles/ag-theme-alpine.css"; // Optional theme CSS
import Header from "../components/Header";
import LoaderComp from "../components/LoaderComp";
import InstanceButton from "../components/InstanceButton";
import EC2Table from "../components/instance_components/Table";
import InputContainer from "../components/instance_components/InputContainer";
import showErrorSnackbar from "../components/instance_components/Snackbar";
import Backdrop from "../components/instance_components/Modal/Backdrop";
import Modal from "../components/instance_components/Modal/Modal";
import RegionDropdown from "../components/instance_components/RegionDropDown";
import { ShimmerTable } from "react-shimmer-effects";
import { useSelector } from "react-redux";
import HoverDropdown from "../components/HoverDropdown";
import { useNavigate } from "react-router-dom";
import SessionExpiredDialog from "../components/SessionExpiredDialog";

const Instances = () => {
  const dayHashmap = {
    MON: 0,
    TUE: 1,
    WED: 2,
    THU: 3,
    FRI: 4,
    SAT: 5,
    SUN: 6,
  };

  const numHashmap = {
    0: "MON",
    1: "TUE",
    2: "WED",
    3: "THU",
    4: "FRI",
    5: "SAT",
    6: "SUN",
  };

  const regionHashMap = {
    "Hyderabad (ap-south-2)": "ap-south-2",
    "Mumbai (ap-south-1)": "ap-south-1",
    "Singapore (ap-southeast-1)": "ap-southeast-1"
  };

  const navigate = useNavigate();
  const regions = ["Mumbai (ap-south-1)", "Hyderabad (ap-south-2)", "Singapore (ap-southeast-1)"];
  const [instanceTypes, setInstanceTypes] = useState([]);
  const [instanceData, setInstanceData] = useState([]);
  const [ruleData, setRuledata] = useState([]);
  // const { token } = useAuthToken();
  const [isRuleLoading, setRuleLoading] = useState(true);
  const [isLoading, setLoading] = useState(true);
  const [isEnabled, setEnabled] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [tableLength, setTableLength] = useState(0);
  const [isAdmin, setIsAdmin] = useState(null);

  const [alertTitle, setAlertTitle] = useState();
  const [alertText, setAlertText] = useState();
  const [label, setLabel] = useState();
  const [modalArgs, setModalArgs] = useState({});
  // const [region, setRegion] = useState("Mumbai (ap-south-1)");
  const [region, setRegion] = useState("Mumbai (ap-south-1)");
  // const { email } = useAuthToken();
  const { email, token, tokenExpiryTime} = useSelector((state) => state.auth);

  const toggleModal = (value) => {
    setShowModal(value);
  };

  // Function to fetch instance data
  const headers = {
    Authorization: `Bearer ${token}`, // Use 'Bearer' followed by a space before the token
    "Content-Type": "application/json", // Set the content type if needed
  };

  const fetchAdminStatus = async () => {
    setLoading(true);
    let adminStatus = false;
    try {
      const response = await axios.get(
        "api/params/adminStatus",

        { headers }
      );
      // console.log(responseStart);
      if (response.data.message !== "Successful") {
        throw new Error("Some error occurred while user status");
      }
      adminStatus = response.data.isAdmin;

      // console.log(ruleStatusData);
    } catch (err) {
      // console.error("Error fetching data:", err);
      return showErrorSnackbar(err.message);
    }
    setIsAdmin(adminStatus);
    // console.log(adminStatus, isAdmin);
  };

  const fetchInstanceTypes = async () => {
    setLoading(true);
    let instanceTypesData = [];
    try {
      const response = await axios.get(
        "api/instances/instanceTypes",

        { headers }
      );
      // console.log(responseStart);
      if (response.data.message !== "Successful") {
        throw new Error("Some error occurred while getting instance types");
      }
      instanceTypesData = response.data.data;

      // console.log(ruleStatusData);
    } catch (err) {
      // console.error("Error fetching data:", err);
      showErrorSnackbar(err.message);
    }
    setInstanceTypes(instanceTypesData);
    // setLoading(false);
  };

  const fetchRuleData = async (region) => {
    setRuleLoading(true);
    let ruleStatusData = [];
    try {
      const responseStart = await axios.post(
        "api/timetrigger/status",

        {
          region: regionHashMap[region],
          setStart: true,
        },
        { headers }
      );
      // console.log(responseStart);
      if (responseStart.data.message !== "Successful") {
        throw new Error(
          "Some error occurred while getting time trigger status"
        );
      }
      const startStatusData = responseStart.data;

      ruleStatusData.push(startStatusData);
      const responseStop = await axios.post(
        "api/timetrigger/status",

        {
          region: regionHashMap[region],
          setStart: false,
        },
        { headers }
      );
      if (responseStop.data.message !== "Successful") {
        throw new Error(
          "Some error occurred while getting time trigger status"
        );
      }
      const stopStatusData = responseStop.data;

      ruleStatusData.push(stopStatusData);
      // console.log(ruleStatusData);
    } catch (err) {
      // console.error("Error fetching data:", err);
      showErrorSnackbar(err.message);
    }
    setRuledata(ruleStatusData);
    setRuleLoading(false);
  };

  const fetchInstanceData = async (region) => {
    // ... Fetch instance data using AWS SDK (as shown in previous response)
    // Set instanceData state with the fetched data
    setLoading(true);
    // console.log(regionHashMap[region]);
    let fetchedInstanceData = [];
    try {
      const response = await axios.post(
        "api/instances/status",
        {
          region: regionHashMap[region],
          isAdmin: isAdmin,
        },
        {
          headers,
        }
      );
      if (response.data.message !== "Successful") {
        throw new Error(
          "Some error occurred while fetching status information"
        );
      }
      fetchedInstanceData = response.data.data;
      let i = 0;
      for (i = 0; i < fetchedInstanceData.length; i++) {
        if (
          fetchedInstanceData[i].InstanceStatus !== "running" &&
          fetchedInstanceData[i].InstanceStatus !== "stopped"
        ) {
          setEnabled(false);
          break;
        }
      }
      if (i === fetchedInstanceData.length) setEnabled(true);
    } catch (err) {
      // console.error("Error fetching data:", err);
      showErrorSnackbar(err.message);
      return;
    }

    setInstanceData(fetchedInstanceData);
    setTableLength(fetchedInstanceData.length);
    setLoading(false);
  };

  const refreshData = async (region) => {
    // console.log(region);
    await fetchRuleData(region);
    await fetchInstanceData(region);
  };
  useEffect(() => {
    // Fetch instance data when the component mounts
    setRegion("Mumbai (ap-south-1)");
    // console.log(region);
    fetchAdminStatus();
  }, []);

  useEffect(() => {
    if (isAdmin !== null) {
      fetchInstanceTypes();
      refreshData(region);
    }
  }, [isAdmin]);

  const startAllInstance = async () => {
    // Use AWS SDK to start the instance by instanceId
    // After starting the instance, you may want to update the instance's status in instanceData
    // let instanceList = [instanceId];

    // data.append("paramValue", [instanceId]);
    // data.append("email", "john@example.com");
    toggleModal(false);
    try {
      setLoading(true);
      const response = await axios.post(
        "api/instances/startAll",
        { email: email, region: regionHashMap[region] },
        { headers }
      );
      if (response.data.message !== "Successful") {
        throw new Error("Some error occurred while starting all instances");
      }
    } catch (err) {
      // console.error("Error fetching data:", err);
      showErrorSnackbar(err.message);
    }
    await fetchInstanceData(region);
  };

  // Function to start an instance
  const startInstance = async (args) => {
    // Use AWS SDK to start the instance by instanceId
    // After starting the instance, you may want to update the instance's status in instanceData
    toggleModal(false);
    const instanceId = args.instanceId;
    let instanceList = [instanceId];

    args.setLoading(true);
    try {
      // console.log("headers", headers);
      const response = await axios.post(
        "api/instances/startSpecific",

        {
          email: email,
          paramValue: instanceList,
          region: regionHashMap[region],
        },
        { headers }
      );
      if (response.data.message !== "Successful") {
        throw new Error("Some error occurred while starting instance");
      }
    } catch (err) {
      // console.error("Error fetching data:", err);
      showErrorSnackbar(err.message);
    }
    args.setLoading(false);
    await args.fetchInstanceData(
      instanceId,
      regionHashMap[region],
      isAdmin,
      setEnabled
    );
    // await fetchInstanceData(region);
  };

  const stopAllInstance = async () => {
    // Use AWS SDK to start the instance by instanceId
    // After starting the instance, you may want to update the instance's status in instanceData
    // let instanceList = [instanceId];

    // data.append("paramValue", [instanceId]);
    // data.append("email", "john@example.com");
    toggleModal(false);
    setLoading(true);
    try {
      const response = await axios.post(
        "api/instances/stopAll",
        { email: email, region: regionHashMap[region] },
        { headers }
      );
      if (response.data.message !== "Successful") {
        throw new Error("Some error occurred while stopping all instances");
      }
      // fetchedInstanceData = responseData.data
    } catch (err) {
      // console.error("Error fetching data:", err);
      showErrorSnackbar(err.message);
    }
    await fetchInstanceData(region);
  };

  // Function to stop an instance
  const stopInstance = async (args) => {
    // Use AWS SDK to stop the instance by instanceId
    // After stopping the instance, you may want to update the instance's status in instanceData
    toggleModal(false);
    const instanceId = args.instanceId;
    let instanceList = [instanceId];

    // data.append("paramValue", [instanceId]);
    // data.append("email", "john@example.com");
    args.setLoading(true);
    try {
      const response = await axios.post(
        "api/instances/stopSpecific",

        {
          email: email,
          paramValue: instanceList,
          region: regionHashMap[region],
        },
        { headers }
      );
      if (response.data.message !== "Successful") {
        throw new Error("Some error occurred while stopping the instance");
      }
      // fetchedInstanceData = responseData.data
    } catch (err) {
      // console.error("Error fetching data:", err);
      showErrorSnackbar(err.message);
    }
    await args.fetchInstanceData(
      instanceId,
      regionHashMap[region],
      isAdmin,
      setEnabled
    );
  };

  // Function to add/remove an instance from the ignore list
  const addIgnoreInstance = async (
    instanceId,
    setRowLoading,
    fetchRowInstanceData
  ) => {
    // Implement logic to add/remove the instance from the ignore list
    let instanceList = [instanceId];
    setRowLoading(true);
    try {
      const response = await axios.post(
        "api/params/store",

        {
          email: email,
          paramValue: instanceList,
        },

        { headers }
      );
      if (response.data.message !== "Successful") {
        throw new Error(
          "Some error occurred while adding the instance to ignore list"
        );
      }
      // fetchedInstanceData = responseData.data
    } catch (err) {
      // console.error("Error fetching data:", err);
      showErrorSnackbar(err.message);
    }
    await fetchRowInstanceData(
      instanceId,
      regionHashMap[region],
      isAdmin,
      setEnabled
    );
  };

  const removeIgnoreInstance = async (
    instanceId,
    setRowLoading,
    fetchRowInstanceData
  ) => {
    // Implement logic to add/remove the instance from the ignore list
    let instanceList = [instanceId];
    setRowLoading(true);
    try {
      const response = await axios.post(
        "api/params/remove",

        {
          email: email,
          paramValue: instanceList,
        },
        { headers }
      );
      // fetchedInstanceData = responseData.data
      if (response.data.message !== "Successful") {
        throw new Error(
          "Some error occurred while removing the instance from ignore list"
        );
      }
    } catch (err) {
      console.error("Error fetching data:", err);
    }
    await fetchRowInstanceData(
      instanceId,
      regionHashMap[region],
      isAdmin,
      setEnabled
    );
  };

  const expressionGenerator = (timeData, daysData) => {
    let timeValues = timeData.split(":");
    let hour = parseInt(timeValues[0]);
    let min = parseInt(timeValues[1]);
    let hourCorrector = 0;
    let dayCorrector = 0;
    let dayValues = [];
    min = min - 30;
    if (min < 0) {
      hourCorrector = 1;
      min = min + 60;
    }
    hour = hour - 5 - hourCorrector;
    if (hour < 0) {
      dayCorrector = 1;
      hour += 24;
    }
    for (let i = 0; i < daysData.length; i++) {
      dayValues.push(dayHashmap[daysData[i]]);
    }

    for (let i = 0; i < dayValues.length; i++) {
      dayValues[i] -= dayCorrector;
      if (dayValues[i] < 0) dayValues[i] += 7;
    }
    dayValues.sort();
    let finalDaysInput = [];
    for (let i = 0; i < daysData.length; i++) {
      finalDaysInput.push(numHashmap[dayValues[i]]);
    }
    let dayString = finalDaysInput.join(",");

    let scheduleExpression = `${min} ${hour} ? * ${dayString} *`;
    return scheduleExpression;
  };

  const enableTimeTriggerCombined = async (
    timeData,
    daysData,
    instanceId,
    setStart,
    email
  ) => {
    let scheduleExpression = "";
    try {
      // scheduleExpression = scheduleExpression.trim();
      if (timeData.length === 0 || daysData.length === 0) {
        throw new Error("Please enter complete input");
      }
      scheduleExpression = expressionGenerator(timeData, daysData);
      setRuleLoading(true);
      // parser.parseExpression(scheduleExpression);
      const response = await axios.post(
        "api/timetrigger/enable",

        {
          paramValue: scheduleExpression,
          setStart: setStart,
          email: email,
          instanceId: instanceId,
          region: regionHashMap[region],
        },
        { headers }
      );
      // fetchedInstanceData = responseData.data
      if (response.data.message !== "Successful") {
        if (
          response.data.message ===
          "Time difference must be greater than 5 minutes"
        ) {
          throw new Error(response.data.message);
        }
        throw new Error("Some error occurred while enabling the time trigger");
      }
    } catch (err) {
      // console.error("Error fetching data:", err);
      showErrorSnackbar(err.message);
      setRuleLoading(false);
      return;
    }
    await fetchRuleData(region);
  };

  const disableTimeTriggerCombined = async (instanceId, setStart, email) => {
    setRuleLoading(true);
    try {
      const response = await axios.post(
        "api/timetrigger/disable",

        {
          instanceId: instanceId,
          setStart: setStart,
          email: email,
          region: regionHashMap[region],
        },
        { headers }
      );
      if (response.data.message !== "Successful") {
        throw new Error("Some error occurred while disabling the time trigger");
      }
      // fetchedInstanceData = responseData.data
    } catch (err) {
      // console.error("Error fetching data:", err);
      showErrorSnackbar(err.message);
    }
    await fetchRuleData(region);
  };

  const enableTimeTriggerSpecific = async (
    timeData,
    daysData,
    instanceId,
    setStart,
    email,
    typeToStart,
    id,
    setStartScheduleLoading,
    fetchStartScheduleList
  ) => {
    let scheduleExpression = "";
    try {
      // scheduleExpression = scheduleExpression.trim();
      if (timeData.length === 0 || daysData.length === 0) {
        throw new Error("Please enter complete input");
      }
      scheduleExpression = expressionGenerator(timeData, daysData);
      setStartScheduleLoading(true);
      // parser.parseExpression(scheduleExpression);
      const response = await axios.post(
        "api/timetrigger/enableSpecific",

        {
          paramValue: scheduleExpression,
          setStart: setStart,
          email: email,
          instanceId: instanceId,
          region: regionHashMap[region],
          typeToStart: typeToStart,
          id: id,
        },
        { headers }
      );
      // fetchedInstanceData = responseData.data
      if (response.data.message !== "Successful") {
        if (
          response.data.message ===
          "Time difference must be greater than 5 minutes"
        ) {
          throw new Error(response.data.message);
        }
        throw new Error("Some error occurred while enabling the time trigger");
      }
    } catch (err) {
      // console.error("Error fetching data:", err);
      showErrorSnackbar(err.message);
      setStartScheduleLoading(false);
      setLoading(false);
      return;
    }
    await fetchStartScheduleList(instanceId);
    // setStartScheduleLoading(false);
  };

  const disableTimeTriggerSpecific = async (
    instanceId,
    setStart,
    email,
    id,
    setStopScheduleLoading,
    fetchScheduleList
  ) => {
    setStopScheduleLoading(true);
    try {
      const response = await axios.post(
        "api/timetrigger/disableSpecific",

        {
          instanceId: instanceId,
          setStart: setStart,
          email: email,
          region: regionHashMap[region],
          id: id,
        },
        { headers }
      );
      if (response.data.message !== "Successful") {
        throw new Error("Some error occurred while disabling the time trigger");
      }
      // fetchedInstanceData = responseData.data
    } catch (err) {
      // console.error("Error fetching data:", err);
      setStopScheduleLoading(false);
      showErrorSnackbar(err.message);
    }
    await fetchScheduleList(instanceId);
    // setStopScheduleLoading(false);
  };

  const changeInstanceType = async (args) => {
    const instanceId = args.instanceId;
    const instanceType = args.instanceType;
    setShowModal(false);
    args.setLoading(true);
    try {
      const response = await axios.post(
        "api/instances/changeType",

        {
          instanceId: instanceId,
          email: email,
          region: regionHashMap[region],
          instanceType: instanceType,
        },
        { headers }
      );
      if (response.data.message !== "Successful") {
        throw new Error("Some error occurred while changing instance type");
      }
      // fetchedInstanceData = responseData.data
    } catch (err) {
      // console.error("Error fetching data:", err);

      return showErrorSnackbar(err.message);
    }
    await args.fetchInstanceData(
      instanceId,
      regionHashMap[region],
      isAdmin,
      setEnabled
    );
  };

  // const startAllDummyFunction = () => {
  // 	toggleModal(false);
  // 	console.log("Triggered start all dummy function!");
  // };

  // const stopAllDummyFunction = () => {
  // 	toggleModal(false);
  // 	console.log("Triggered stop all dummy function!");
  // };

  // const startSpecificDummyFunction = (id) => {
  // 	toggleModal(false);
  // 	console.log(`Triggered start specific dummy function "${id}"`);
  // };

  // const stopSpecificDummyFunction = (id) => {
  // 	toggleModal(false);
  // 	console.log(`Triggered stop specific dummy function "${id}"`);
  // };

  const functionHashMap = {
    startAll: startAllInstance,
    stopAll: stopAllInstance,
    startSpecific: startInstance,
    stopSpecific: stopInstance,
    changeInstanceType: changeInstanceType,
  };

  const showAlert = async (title, text, labelText, args) => {
    toggleModal(true);
    setAlertTitle(title);
    setAlertText(text);
    setLabel(labelText);

    if (args) setModalArgs(args);
    else setModalArgs(null);
  };

  return (
    <div class="w-full h-full">
      {showModal ? (
        <>
          <Backdrop />
          <Modal
            title={alertTitle}
            canConfirm={true}
            confirmText={"Confirm"}
            onConfirm={
              modalArgs === null
                ? functionHashMap[label]
                : () => {
                    functionHashMap[label](modalArgs);
                  }
            }
            canCancel={true}
            onCancel={() => {
              toggleModal(false);
            }}
          >
            {alertText}
          </Modal>
        </>
      ) : (
        <></>
      )}
      <Header>
        <div>
          <HoverDropdown
            title={"Instances"}
            content={{
              Home: () => {
                navigate("/live_data");
              },
              Market: () => {
                navigate("/market");
              },
              Instances: () => {
                navigate("/instances");
              },
              Allocation: () => {
                navigate("/allocation");
              },
            }}
            icon={false}
          />
        </div>
      </Header>
      {new Date().getTime() > tokenExpiryTime ? (
        <SessionExpiredDialog
          expired={new Date().getTime() > tokenExpiryTime}
        />
      ) : (
        <>
          {isLoading || isRuleLoading ? (
            <div class="flex justify-center items-center mt-20">
              <LoaderComp />
            </div>
          ) : (
            <>
              <div
                className="ag-theme-alpine"
                style={{
                  width: "80%",
                  // height: `${tableHeight}px`,
                  marginLeft: "auto",
                  marginRight: "auto",
                  marginTop: 20,
                }}
              >
                {/* <AgGridReact
              rowData={rowData}
              columnDefs={columnDefs}
              defaultColDef={defaultColDef}
              animateRows={true}
            /> */}
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "center",
                    marginBottom: "10px",
                  }}
                >
                  <div
                    style={{
                      width: "25%",
                    }}
                  >
                    {" "}
                    <RegionDropdown
                      selectedVal={region}
                      content={regions}
                      setSelectedVal={setRegion}
                      onSelect={refreshData}
                    />{" "}
                  </div>
                  {tableLength === 0 ? (
                    <></>
                  ) : (
                    <div
                      style={{
                        display: "inline-block",
                        marginLeft: "20px",
                      }}
                    >
                      {isAdmin || (!isAdmin && ruleData[0].enabled) ? (
                        <InputContainer
                          label={"Start Schedule (in IST)"}
                          placeholder={
                            ruleData[0].enabled
                              ? ruleData[0].scheduleExpression
                              : "_"
                          }
                          onClick={
                            ruleData[0].enabled
                              ? disableTimeTriggerCombined
                              : enableTimeTriggerCombined
                          }
                          type={ruleData[0].enabled ? "Disable" : "Enable"}
                          enabled={ruleData[0].enabled}
                          setStart={true}
                          isAdmin={isAdmin}
                          isMachineSpecific={false}
                        />
                      ) : (
                        <></>
                      )}
                      {isAdmin || (!isAdmin && ruleData[1].enabled) ? (
                        <InputContainer
                          label={"Stop Schedule (in IST)"}
                          placeholder={
                            ruleData[1].enabled
                              ? ruleData[1].scheduleExpression
                              : "Cron expression"
                          }
                          onClick={
                            ruleData[1].enabled
                              ? disableTimeTriggerCombined
                              : enableTimeTriggerCombined
                          }
                          type={ruleData[1].enabled ? "Disable" : "Enable"}
                          enabled={ruleData[1].enabled}
                          setStart={false}
                          isAdmin={isAdmin}
                          isMachineSpecifc={false}
                        />
                      ) : (
                        <></>
                      )}
                    </div>
                  )}
                </div>

                {tableLength === 0 ? (
                  <div
                    style={{
                      marginTop: "20px",
                      marginBottom: "20px",
                      textAlign: "center",
                      fontSize: "20px",
                    }}
                  >
                    Sorry. No instances to display !
                  </div>
                ) : (
                  <EC2Table
                    instanceData={instanceData}
                    addIgnoreInstance={addIgnoreInstance}
                    removeIgnoreInstance={removeIgnoreInstance}
                    enableTimeTriggerSpecific={enableTimeTriggerSpecific}
                    disableTimeTriggerSpecific={disableTimeTriggerSpecific}
                    showAlert={showAlert}
                    instanceTypes={instanceTypes}
                  />
                )}
              </div>
              {tableLength === 0 ? (
                <></>
              ) : (
                <div class="flex mx-auto justify-center w-1/5 items-center">
                  {isAdmin ? (
                    <>
                      <InstanceButton
                        text={"Start All"}
                        color={"green-500"}
                        textCol={"white"}
                        // onClick={startAllInstance}
                        onClick={async () => {
                          await showAlert(
                            "Alert!",
                            "Are you sure you want to start all the EC2 machines (except in ignore list)?",
                            "startAll",
                            null
                          );
                        }}
                        isEnabled={isEnabled}
                      />
                      <InstanceButton
                        text={"Stop All"}
                        color={"red-500"}
                        textCol={"white"}
                        // onClick={stopAllInstance}
                        onClick={async () => {
                          await showAlert(
                            "Alert!",
                            "Are you sure you want to stop all the EC2 machines (except in ignore list)?",
                            "stopAll",
                            null
                          );
                        }}
                        isEnabled={isEnabled}
                      />
                    </>
                  ) : (
                    <></>
                  )}
                  <InstanceButton
                    text={"Refresh"}
                    color={"zinc-300"}
                    textCol={"black"}
                    onClick={async () => {
                      await refreshData(region);
                    }}
                    isEnabled={true}
                  />
                </div>
              )}{" "}
              {tableLength === 0 ? (
                <></>
              ) : (
                <div
                  style={{
                    marginTop: "20px",
                    marginBottom: "20px",
                    textAlign: "center",
                  }}
                >
                  <h1>Utility Conditions</h1>
                  <ul>
                    <li>
                      Start All :: will start all instances which are not
                      present in ignore list and don't have individual scheduler
                      enabled.
                    </li>
                    <li>
                      Stop All :: will stop all instances which are not present
                      in ignore list and don't have individual scheduler
                      enabled.
                    </li>
                    <li>
                      Start :: will start the instance for which it was pressed.
                    </li>
                    <li>
                      Stop :: will stop the instance for which it was pressed.
                    </li>
                    <li>
                      Enable :: (on pressing "Enable") will enable the group
                      scheduler for the instance.
                    </li>
                    <li>
                      Disable :: (on pressing "Disable") will disable the group
                      scheduler for the instance.
                    </li>
                    <li>
                      Scheduler (on top) :: will schedule start and stop for all
                      instances which are not present in ignore list and don't
                      have individual scheduler enabled.
                    </li>
                    <li>
                      Scheduler (in table) :: will schedule start and stop for
                      the instance for which it was set.
                    </li>
                  </ul>
                </div>
              )}
            </>
          )}
        </>
      )}
    </div>
  );
};

export default Instances;
